import React from 'react';
import DataTable from 'components/DataTableWithHeader'
import {
    Edit as EditIcon,
} from '@material-ui/icons'
import ArtWorks from 'data/ArtWorks';
import MuiPaper from '@material-ui/core/Paper';
import Image from 'components/Img'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components';
import TYPES from 'Assets/json/TypesOeuvre.json'

import { photoItemWithType } from 'Util/Oeuvre'
import { sortAlpha, sortNumbers } from 'Util/Sorting'
import { getStringSurfaceDiameter, getStringSurfaceRect, getLastPrice } from 'Util/Oeuvre';
import { useCurrentPermissionProfile } from 'redux/hooks';
import Moment from 'moment';


const Paper = styled(MuiPaper)`
    padding: 10px;
`

export default ({ data = ArtWorks, actions, onChange, onClickEdit, headerActions }) => {

    const history = useHistory();
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    
    const permissionProfile = useCurrentPermissionProfile();
    const userIsComitee = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === null;


    function sorting ( category, order ) {

        data.sort(function (a, b) {

            if ( (category === "archive") || (category === "annee_creation") ) {
                return sortNumbers(a[category], b[category], order)

            } else if ( (category === "titre") ) {
                return sortAlpha(a[category], b[category], order)

            } else if ( (category === "type") ) {
                const type_1 = a[category]-1
                const type_2 = b[category]-1
                var type_one = ""
                var type_two = ""

                if ( TYPES[type_1] ) {
                    type_one =  TYPES[type_1].loc[i18n.language]
                }
                if ( TYPES[type_2] ) {
                    type_two =  TYPES[type_2].loc[i18n.language]
                }
                return sortAlpha(type_one, type_two, order)
            }
            return -1
        });
    }

    //console.log("data : ", data)
    
    return (
        <Paper elevation={3}>
            {headerActions && (
                <div>
                    {headerActions}
                </div>
            )}
            <DataTable
                data={data}
                defaultOrderBy={"archive"}
                onRequestSort={sorting}
                config={{
                    onClickRow: (artwork, index) => {
                      //  console.log("Search onClickRow : ", artwork)

                        history.push("/artwork/" + artwork.objectid, {
                            context: "search_result",
                            search: {
                                index
                            }
                        });

                    },
                    cells: [
                        { title: t('nb_archive'), key: 'archive', bold:true, width:120 },
                        { title: t('photo'), key: 'filename', width:100, sort:false  },
                        { title: t('title'), key: 'titre', width:600  },
                        { title: t('creation_year'), key: 'annee_creation', width:100 },
                        { title: t('type_doeuvre'), key: 'type', width:200 },

                        ...(userIsComitee ? [
                        { title: t('surface'), key: 'surface', width:100 },
                        { title: t('date'), key: 'date_prix', width:200 },
                        { title: t('prix'), key: 'prix', width:200 },
                        { title: t('genre'), key: 'genre', width:200 },
                        { title: t('interlocuteur'), key: 'interlocuteur', width:200 }
                    ] : []),
                    ],
                    actions: actions || [
                        {
                            icon: <EditIcon />, title: "Edit",
                            onClick: (id, artwork) => {
                                window.open("/artwork/" + artwork.objectid, "_blank")
                            }
                        },
                    ]
                }}
                getCell={(item, attr_name) => {

                    if (attr_name === "filename") {

                        const frontPhotoItem = photoItemWithType(item['photo_items'], "1")

                        if ( frontPhotoItem ) {
                            return (
                                <Image
                                    imageWidth={60}
                                    style={{justifyContent: 'flex-start'}}
                                    alt="" filename={frontPhotoItem.filename}
                                />
                            )
                        }
                    } else if ( attr_name === "type") {

                        const type = item["type"]-1
                        if ( TYPES[type] ) {
                            return TYPES[type].loc[i18n.language]
                        }
                    } else if ( attr_name === "surface") {
                        return item.diametre ? getStringSurfaceDiameter(item) : getStringSurfaceRect(item)

                    } else if ( attr_name === "date_prix") {
                        const price = getLastPrice(item)

                        if ( price ) {  
                            return Moment(price['date_enchere']).format('DD-MM-YYYY')
                        }
                        return ""
                        
                    } else if ( attr_name === "prix") {
                        const price = getLastPrice(item)

                        if ( price ) {  
                            var prix = null
                            
                            if ( price["prix_de_vente"] ) {
                                prix = Intl.NumberFormat('fr-FR').format(price["prix_de_vente"])
                            } else if ( price["prix_marteau"] ) {
                                prix = Intl.NumberFormat('fr-FR').format(price["prix_marteau"])
                            } 
                            if ( prix ) {
                                if ( price["devise_prix_de_vente"] === "Euros" ) {
                                    return "€" + prix
                                } else if ( price["devise_prix_de_vente"] === "Dollars" ) {
                                    return "$" + prix
                                }
                                return prix                                
                            }
                        }
                        return ""

                    } else if ( attr_name === "genre") {
                        const price = getLastPrice(item)

                        if ( price ) {

                            return price["type"]
                        }
                        return ""

                    } else if ( attr_name === "interlocuteur") {
                        const price = getLastPrice(item)
                        if ( price ) {
                            return price["source"]
                        }
                        return ""
                    }
                    return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
                }}
            />
        </Paper>

    )
}