import React, { } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import { Grid, Paper } from '@material-ui/core';

import Accordion from 'components/Accordion'

import Tabs, {
    TabsContainer, TabsPanels as OriginalTabsPanels
} from 'components/Tabs'

import Form from 'components/Form';

import { useCurrentPermissionProfile, useCurrentUserEmail } from 'redux/hooks';
import { getOeuvreStatus } from 'Util/OeuvreStatus';
import { listeDesLocalisationsSearch, emplacementDesLocalisations } from 'Util/Listes'
import { getOeuvreElements, getOeuvreMateriaux, getOeuvreCouleurs, getOeuvreFamilles } from 'Util/OeuvreCriteres'


const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)



export default ({ data, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();

    const permissionProfile = useCurrentPermissionProfile();
    const userEmail = useCurrentUserEmail();

    const canSeeAccordeons = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeMoreTabs = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === 'adriana' || permissionProfile === null || userEmail === "ag.ateliersoto@gmail.com";
    const canSeeFabrication = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeeAuthentification = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === null;

    const mainForm = [

        [
        { label: t("nb_archive"), attr: "archive", type: "text", size: { label: 150, field: 100 }},
        
        { label: " - ", attr: "archive_max", type: "text", size: { label: 10, field: 100 }},
        ],
        
        { label: t("title"), attr: "titre", type: "text", size: { label: 150, field: 300 } },
        { label: t("other_title"), attr: "autre_titre", type: "text", size: { label: 150, field: 300 }},

        [
            { label: t("creation_year"), attr: "annee_creation_min", type: "text", size: { label: 150, field:80 }},
            { label: "", attr: "annee_creation_max", type: "text", size: { left:20, label: 0, field:80 }},
            { label: t("circa"), attr: "circa_creation", type: "checkbox", size: { label: 20, field: 100 } },
        ],
        [
            { label: t("annee_de_realisation"), attr: "annee_realisation_min", type: "text", size: { label: 150, field:80 }},
            { label: "", attr: "annee_realisation_max", type: "text", size: { left:20, label: 0, field:80 }},
            { label: t("circa"), attr: "circa_realisation", type: "checkbox", size: { label: 20, field: 100 } },
        ],
        {
            label: t("type_doeuvre"), attr: 'type', type: "select",
            selectType: 'artworktypes', size: { label: 150, field: 300 }
        },
        { label: t("proprietaire_actuel"), attr: "dernier_proprietaire", type: "text", size: { label: 150, field: 300 } },
                
        [
            { label: t("localisation_actuelle"), attr: "derniere_localisation", type: "autocomplete", autocomplete: {
                type: 'free',
                options: listeDesLocalisationsSearch()
                }, size: { label: 150, field:350 }
            },
            { label: t("match_exact"), attr: "match_derniere_localisation", type: "checkbox", size: { label: 0, field: 100 } },

        ],

        { label: t("emplacement_actuel"), attr: "dernier_emplacement", type: "autocomplete", autocomplete: {
            type: 'free',
            options: emplacementDesLocalisations()
            }, size: { label: 150, field:400 }
        },

        { label: t("reserve"), attr: "statut_reserve", type: "checkbox", size: { label: 150, field: 100 } },

        [{ label: t("hauteur"), attr: "hauteur_min", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
        { label: "", attr: "hauteur_max", type: "text", textType: 'measure', size: { left: 10, label: 20, field: 200 } },],

        [{ label: t("largeur"), attr: "largeur_min", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
        { label: "", attr: "largeur_max", type: "text", textType: 'measure', size: { left: 10, label: 20, field: 200 } },],

        [{ label: t("profondeur"), attr: "profondeur_min", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
        { label: "", attr: "profondeur_max", type: "text", textType: 'measure', size: { left: 10, label: 20, field: 200 } },],

        [{ label: t("diametre"), attr: "diametre_min", type: "text", textType: 'measure', size: { label: 150, field: 200 } },
        { label: "", attr: "diametre_max", type: "text", textType: 'measure', size: { left: 10, label: 20, field: 200 } },],

        { label: t("dimensions_HT"), attr: "dimensions_ht", type: "text", size: { label: 150, field: 300 } },

        { label: t("code"), attr: "mots_clefs", type: "text", size: { label: 150, field: 300 } },

        { label: t("editeur"), attr: "editer_par", type: "text", size: { label: 150, field: 300 } },
        { label: t("serie"), attr: "serie", type: "text", size: { label: 150, field: 300 } },
        { label: t("tirage"), attr: "tirage", type: "text", size: { label: 150, field: 300 } },

        [
            { label: t("Photo HD"), attr: "front_photo_hd", type: "checkbox", size: { label: 150, field: 100 } },
            { label: t("Pas de photo HD"), attr: "pas_de_front_photo_hd", type: "checkbox", size: { label: 0, left:0, field:150 } },
            { label: t("Video"), attr: "video", type: "checkbox", size: { label: 0, field: 100 } },
        ]

    ]

   const formRight = [

        [
            { label: t("nb_tacos"), attr: "nb_tacos", type: "text", size: { label: 150, field: 50 }},
            { label: t("- "), attr: "nb_tacos_max", type: "text", size: { label: 0, field: 50 }},
        ],
        [
            { label: t("nb_varillas"), attr: "nb_varillas", type: "text", size: { label: 150, field: 50 }},
            { label: t(" - "), attr: "nb_varillas_max", type: "text", size: { label: 0, field: 50, left:0  }},
        ],
        [
            { label: t("nb_tes"), attr: "nb_tes", type: "text", size: { label: 150, field: 50 }},
            { label: t("- "), attr: "nb_tes_max", type: "text", size: { label: 0, field: 50, left:0 }}
        ],
        
        { label: t("familles"), attr: "familles_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreFamilles()
            },
            size: { label: 150, field: 400 }
        },

        { label: t("elements"), attr: "elements_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreElements()
            },
            size: { label: 150, field: 400 }
        },

        { label: t("couleurs"), attr: "couleurs_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreCouleurs()
            },
            size: { label: 150, field: 400 }
        },
        { label: t("materiaux"), attr: "materiaux_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreMateriaux()
            },
            size: { label: 150, field: 400 }
        },
    ]

    const formCodeAvila = [

        { label: t("code_direction"), attr: "code_direction", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_assistant_direction"), attr: "code_assistant_direction", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_regisseur"), attr: "code_regisseur", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_assistant_regisseur"), attr: "code_assistant_regie", type: "text", multi: 2, size: { label: 200, field: 600 } },
    ]
    const formTechSpecs = [

        { label: t("fiche_technique"), attr: "fiche_technique", type: "checkbox", size: { label: 200, field: 100 } },
        [
            { label: t("date_fiche_technique"), attr: "date_fiche_technique_min", type: "dateselector", size: { label: 200, field: 150 }},
            { label: "", attr: "date_fiche_technique_max", type: "dateselector", size: { left:20, label: 0, field: 150 }},
        ],

        { label: t("details_techniques"), attr: "details_techniques", type: "text", multi: 3, size: { label: 200, field: 600 } },
        { label: t("contraintes_dinstallation"), attr: "contraintes_installation", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("materiel_et_equipes_necessaires"), attr: "materiel_necessaire", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("autres_informations_techniques"), attr: "autres_infos_techniques", type: "text", multi: 2, size: { label: 200, field: 600 } },
    ]

    const localisationForm = [

        [
            { label: t("date"), attr: "date_localisation", type: "dateselector", size: { label: 150, field: 150 } },
            { label: "", attr: "date_localisation_max", type: "dateselector", size: { left:20, label: 0, field: 150 } },
        ],
        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:150 }},
        { label: t("lieu"), attr: "localisation", type: "autocomplete", autocomplete: {
            type: 'free',
            options: listeDesLocalisationsSearch()
            }, size: { label: 150, field:400 }
        },
        { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
            type: 'free',
            options: emplacementDesLocalisations()
            }, size: { label: 150, field:400 }
        },
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:400 }},
        { label: t("pays"), attr: 'pays', type: "select", selectType: 'countries', size: { label: 150, field: 300 } },
        { label: t("remarques"), attr: "remarque", type: "text", size: { label: 150, field:400 }},
    ]


    const conservationForm = [

        [
            { label: t("a_restaurer"), attr: "a_restaurer", type: "checkbox", size: { label: 150, field:50 } },
            { label: t("detruit"), attr: "detruit", type: "checkbox", size: { label: 150, field:50 } },
            { label: t("nonrestaurable"), attr: "nonrestaurable", type: "checkbox", size: { label: 150, field:50 } },
        ],
        [
            { label: t("date_ouverture"), attr: "date_ouverture_min", type: "dateselector", size: { label: 150, field: 150 } },
            { label: "", attr: "date_ouverture_max", type: "dateselector", size: { left:20, label: 0, field: 150 } },
        ],

        [
            { label: t("date_dernier_constat"), attr: "date_constat_min", type: "dateselector", size: { label: 150, field: 150 } },
            { label: "", attr: "date_constat_max", type: "dateselector", size: { left:20, label: 0, field: 150 } },
        ],
        { label: t("realise_par"), attr: "realise_par", type: "text", size: { label: 150, field: 300 }},
        { label: t("restauration"), attr: "restauration", type: "text", size: { label: 150, field: 500 } },
    ]


    const fabricationForm = [

        { label: t("fabrique_par"), attr: "fabrique_par", type: "text", size: { label: 150, field: 300 } },
        { label: t("observations"), attr: "fabrication_observations", type: "text", size: { label: 150, field: 500 } },
        { label: t("plan"), attr: "plan", type: "checkbox", size: { label: 150, field: 100 } },
        { label: t("ft_verifie_par_le_comite"), attr: "fiche_technique_verifie_comite", type: "checkbox", size: { label: 150, field: 100 } },
        { label: t("code_comite"), attr: "code", type: "text", size: { label: 150, field: 300 } },
    ]

    const transportForm = [

        { label: t("nimp15"), attr: "nimp15", type: "checkbox", size: { label: 150, field:150 }},
                    
        [
            { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field:250 }},
            { label: t(""), attr: "largeur_max", type: "text", textType: 'measure', size: { label: 100, field:250 }},
        ],
        [
            { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field:250 }},
            { label: t(""), attr: "profondeur_max", type: "text", textType: 'measure', size: { label: 100, field:250 }},
        ],
        [
            { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field:250 }},
            { label: t(""), attr: "hauteur_max", type: "text", textType: 'measure', size: { label: 100, field:250 }},
        ],
        [        
            { label: t("poids_oeuvre"), attr: "poids", type: "text", textType: 'weight', size: { label: 150, field:250 }},
            { label: t(""), attr: "poids_max", type: "text", textType: 'weight', size: { label: 100, field:250 }},
        ],
        [        
            { label: t("poids_caisse"), attr: "poids_net", type: "text", textType: 'weight', size: { label: 150, field:250 }},
            { label: t(""), attr: "poids_net_max", type: "text", textType: 'weight', size: { label: 100, field:250 }},
        ],
        [        
            { label: t("poids_oeuvre_caisse"), attr: "poids_brut", type: "text", textType: 'weight', size: { label: 150, field:250 }},
            { label: t(""), attr: "poids_brut_max", type: "text", textType: 'weight', size: { label: 100, field:250 }},
        ],

        { label: t("etat_de_la_caisse"), attr: "etat", type: "text", size: { label: 150, field:400 }},

        [
            { label: t("cadre_de_voyage"), attr: "cadre_de_voyage", type: "checkbox", size: { label: 150, field:150 }},
            { label: t("ouverture_verticale"), attr: "ouverture_verticale", type: "checkbox", size: { left: 100, field:150 }},
            { label: t("peinte"), attr: "peinte", type: "checkbox", size: { left: 100, field:150 }},
        ],
        [
            { label: t("a_refaire"), attr: "a_refaire", type: "checkbox", size: { label: 150, field:150 }},
            { label: t("tyvek_ok"), attr: "tyvek_ok", type: "checkbox", size: { label: 50, field:150 }},
            { label: t("cales_ok"), attr: "cales_ok", type: "checkbox", size: { label: 65, field:150 }},
        ],
    ]


    const prixForm = [

        [
            { label: t("surface"), attr: "surface_min", type: "text", textType: 'measure_m2', size: { label: 150, field: 250 } },
            { label: "", attr: "surface_max", type: "text", textType: 'measure_m2', size: { left: 50, label: 20, field: 250 } },
        ],

        [
            { label: t("date"), attr: "date_enchere_min", type: "dateselector", size: { label: 150, field:150 }},
            { label: " - ", attr: "date_enchere_max", type: "dateselector", size: { label: 10, field:150 }},
        ],
        { label: t("type"), attr: 'type_prix', type: "select", options : [
            { label: "Enchère", value: "enchere"},
            { label: "Estimation", value: "estimation"},
            { label: "Prix", value: "prix"},
        ], size: { label: 150, field:200 }},

        { label: t("interlocuteur"), attr: "source", type: "text", size: { label: 150, field:300 }},
        { label: t("numero_exemplaire"), attr: "multiple", type: "text", size: { label: 150, field:100 }},
        { label: t("lot"), attr: "lot", type: "text", size: { label: 150, field:100 }},
        { label: t("devise"), attr: 'devise_prix_de_vente', type: "select", options : [
            { label: "Euros", value: "Euros"},
            { label: "Dollars", value: "Dollars"},
        ], size: { label: 150, field:200 }},
        [
            { label: t("prix_au_marteau"), attr: "prix_au_marteau_min", type: "text", size: { label: 150, field:100 }},
            { label: " - ", attr: "prix_au_marteau_max", type: "text", size: { label: 10, field:100 }},
        ],
        [
            { label: t("prix_de_vente"), attr: "prix_de_vente_min", type: "text", size: { label: 150, field:100 }},
            { label: " - ", attr: "prix_de_vente_max", type: "text", size: { label: 10, field:100 }},
        ],
        [
            { label: t("estimation"), attr: "estimation_min", type: "text", size: { label: 150, field:100 }},
            { label: " - ", attr: "estimation_max", type: "text", size: { label: 10, field:100 }},
        ],
        { label: t("ville"), attr: "ville", type: "text", size: { label: 150, field:400 }},
        { label: t("pays"), attr: 'pays', type: "select",
             selectType: 'countries', size: { label: 150, field: 400 }
        },
        { label: t("remarques"), attr: "remarques", type: "text", size: { label: 150, field:400 }},
    ]

    const authentificationForm = [
        
        [
            { label: t("date"), attr: "date_auth_min", type: "dateselector", size: { label: 150, field:150 }},
            { label: " - ", attr: "date_auth_max", type: "dateselector", size: { label: 10, field:150 }},
        ],
        { label: t("source"), attr: "source_auth", type: "text", size: { label: 150, field: 500 } },

        { label: t("statut"), attr: 'status_auth', type: "select", options : [
            { label: t("en_cours"), value: "en_cours"},
            { label: t("sans_suite"), value: "sans_suite"},
            { label: t("authentifie"), value: "authentifie"},
            { label: t("refuse"), value: "refuse"},
        ], size: { label: 150, field: 300 } },
        { label: t("remarques"), attr: "remarques_auth", type: "text", multi:3 , size: { label: 150, field: 500 } },
        { label: t("caisse"), attr: "caisse", type: "text", multi:2 , size: { label: 150, field: 500 } },

        [
            { label: t("contrat_envoye"), attr: "contrat_envoye", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "contrat_envoye_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("contrat_recu"), attr: "contrat_recu", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "contrat_recu_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("paiement"), attr: "paiement", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "paiement_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("document_didentite"), attr: "document_didentite", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "document_didentite_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [            
            { label: t("date_photo_hd"), attr: "date_photo_hd", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "date_photo_hd_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("arrivee_oeuvre"), attr: "arrivee_oeuvre", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "arrivee_oeuvre_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("retour_oeuvre"), attr: "retour_oeuvre", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "retour_oeuvre_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("certificat"), attr: "certificat", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "certificat_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("certificat_signe"), attr: "signature", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "signature_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("envoi_certificat"), attr: "envoi_certificat", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "envoi_certificat_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
        [
            { label: t("reception_certificat"), attr: "reception_certificat", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t(""), attr: "reception_certificat_max", type: "dateselector", size: { label: 20, field: 150 } },
        ],
    ]

    const multipleForm = [
        
        { label: t("numero_exemplaire"), attr: "numero", type: "text", size: { label: 200, field:100 }},
        
        { label: t("localisation"), attr: "derniere_localisation", type: "autocomplete", autocomplete: {
            type: 'free',
            options: listeDesLocalisationsSearch()
            }, size: { label: 200, field:400 }
        },
        { label: t("emplacement"), attr: "emplacement", type: "autocomplete", autocomplete: {
            type: 'free',
            options: emplacementDesLocalisations()
            }, size: { label: 200, field:400 }
        },

        { label: t("proprietaire"), attr: "dernier_proprietaire", type: "text", size: { label: 200, field:400 }},

        { label: t("etat_administratif"), attr: 'etat', type: "select", options : [
            { label: "A vérifier", value: "averifier"},
            { label: "Dépôt-vente", value: "depotvente"},
            { label: "Disponible", value: "disponible"},
            { label: "Disponible - Modèle d'expo", value: "disponiblemodeledexpo"},
            { label: "Donné", value: "donne"},
            { label: "Non présentable", value: "nonpresentable"},
            { label: "Prêt", value: "pret"},
            { label: "Prêt - Modèle d'expo", value: "pretmodeledexpo"},
            { label: "Réservé", value: "reserve"},
            { label: "Vendu", value: "vendu"},
        ], size: { label: 200, field:300 }},

        { label: t("etat_de_conservation"), attr: 'etat_de_conservation', type: "select", options : [
            { label: "A restaurer", value: "arestaurer"},
            { label: "A vérifier", value: "averifier"},
            { label: "Neuf", value: "neuf"},
            { label: "Bon état", value: "bon"},
            { label: "Moyen", value: "moyen"},
            { label: "Moyen / Mauvais", value: "moyenmauvais"},
            { label: "Mauvais", value: "mauvais"},
            { label: "Détruit", value: "detruit"},
            { label: "Non restaurable", value: "nonrestaurable"},
        ], size: { label: 200, field:300 }},

        { label: t("non_signe"), attr: "nonsigne", type: "checkbox", size: { label: 200, field:100 }},
        { label: t("non_numerote"), attr: "nonnumerote", type: "checkbox", size: { label: 200, field:100 }},

        { label: t("conservation"), attr: "multiple_conservation", type: "text", multi:5, size: { label: 200, field:400 }},
        { label: t("remarques"), attr: "multiple_remarques", type: "text", multi:5, size: { label: 200, field:400 }},
    ]

    return (

        <GridContainer>


            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={mainForm} data={data} onChange={onChange} />
                    </FormContainer>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box elevation={1}>
                    <FormContainer>
                        <Form form={formRight} data={data} onChange={onChange} />
                    </FormContainer>
                </Box>
            </Grid>

            <Grid item xs={12}>

                <Accordion
                    accordions={[

                        ...( canSeeAccordeons ? [
                        {
                            title: t("caracteristiques_techniques"),
                            content: (
                                <FormContainer>
                                    <Form form={formTechSpecs} data={data} onChange={onChange} />
                                </FormContainer>
                            )
                        },
                        {
                            title: t("code_avila"),
                            content: (
                                <FormContainer>
                                    <Form form={formCodeAvila} data={data} onChange={onChange} />
                                </FormContainer>
                            )
                        },
                        {
                            title: t("multiples"),
                            content: (
                                <FormContainer>
                                    <Form form={multipleForm}
                                        data={data.multiple || {}}
                                        onChange={multiple => {
                                            onChange({
                                                ...data,
                                                multiple
                                            })
                                        }}
                                    />
                                </FormContainer>
                            )
                        },

                        {
                            title: t("localisation"),
                            content: (
                                <FormContainer>
                                    <Form form={localisationForm}
                                        data={data.loc || {}}
                                        onChange={loc => {
                                            onChange({
                                                ...data,
                                                loc
                                            })
                                        }}
                                    />
                                </FormContainer>
                            )
                        },
                        {
                            title: t("conservation"),
                            content: (
                                <FormContainer>
                                    <Form form={conservationForm} data={data} onChange={onChange} />
                                </FormContainer>
                            )
                        },
                        {
                            title: t("transport"),
                            content: (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("passeport"), attr: "passeport", type: "checkbox", size: { label: 150, left: 150 } },
                                                { label: t("remarques"), attr: "historique", type: "text", multi: 5, size: { label: 150, field: 500 } },
                                            ]}
                                            data={data}  onChange={onChange}
                                        />
                                        </FormContainer>

                                        <FormContainer>
                                            <Form form={transportForm}
                                                data={data.caisse || {}}
                                                onChange={caisse => {
                                                    onChange({
                                                        ...data,
                                                        caisse
                                                    })
                                                }}
                                            />
                                        </FormContainer>
                                    </Grid>

                                </Grid>
                            )
                        },
                        ] : []),
                    ]}
                />
            </Grid>


            {canSeeMoreTabs && <Grid item xs={12}>

                <TabsContainer orientation={"vertical"}>

                    <Tabs
                        orientation={"vertical"}
                        variant="scrollable"
                        tabs={[

                            {
                                label: t("observations"),
                                panel: (
                                    <TabsPanels>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    [{ label: t("etat_de_la_fiche"), attr: "etat_fiche", type: "autocomplete",
                                                        autocomplete: {
                                                            type: 'local_tags',
                                                            options: getOeuvreStatus()
                                                        },
                                                        size: { label: 150, field: 300 }
                                                    },
                                                    { label: t("source"), attr: "source", type: "text", size: { left:50, label: 100, field: 200 }},
                                                    ],
                                                    [
                                                        { label: t("doublon"), attr: "doublon", type: "checkbox", size: { label: 150, field: 100 } },
                                                        { label: t("alerte_sur_loeuvre"), attr: "alerte_oeuvre", type: "checkbox" , size: { label: 150, field: 100 }},
                                                    ],
                                                    { label: t("observations"), attr: "observations_search", type: "text", multi: 12, size: { label: 150, field: 600 } },

                                                ]} data={data} onChange={onChange} />
                                            </Grid>
                                        </Grid>

                                    </TabsPanels>
                                )
                            },

                            {
                                label: t("historique"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                [
                                                    { label: t("date"), attr: "date_historique_min", type: "dateselector", size: { label: 150, field: 150 } },
                                                    { label: "", attr: "date_historique_max", type: "dateselector", size: { left:20, label: 0, field: 150 } },
                                                ],
                                                { label: t("type"), attr: 'type', type: "select", options : [
                                                    { label: "Vente", value: "vente"},
                                                    { label: "Don", value: "don"},
                                                    { label: "Echange", value: "echange"},
                                                    { label: "Enchère", value: "enchere"},
                                                    { label: "Héritage", value: "heritage"},
                                                    { label: "Autre", value: "autre"}], size: { label: 150, field: 200 }
                                                },
                                                { label: t("proprietaire"), attr: "nouveau_proprio", type: "text", size: { label: 150, field: 400 } },
                                                ]} 
                                                data={data.provenance || {}}
                                                onChange={provenance => {
                                                    onChange({
                                                        ...data,
                                                        provenance
                                                    })
                                                }}
                                            />
                                        </FormContainer>

                                        <FormContainer>
                                            <Form form={[
                                                { label: t("remarques"), attr: "remarques_provenance", type: "text", multi:3, size: { label: 150, field: 400 } },
                                            ]}
                                            data={data}  onChange={onChange}
                                        />

                                        </FormContainer>
                                    </TabsPanels>
                                )
                            },

                            ...(canSeeFabrication ? [
                            {
                                label: t("fabrication"),
                                panel: (
                                    <TabsPanels>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={fabricationForm} data={data} onChange={onChange} />
                                            </Grid>
                                        </Grid>
                                    </TabsPanels>
                                )
                            },
                            ] : []),

                            ...(canSeeAuthentification ? [
                            {
                                label: t("authentification"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("authentification"), attr: "remarques_authentification", type: "autocomplete",
                                                    autocomplete: {
                                                        type: 'free',
                                                        options: [ "Authentifié par J. Soto", "Authentifié par H. Soto", "A confirmer", "Probable", "Douteux", "Certificat Avila", "Certificat par Soto", "Comité Soto"]
                                                    }, size: { label: 150, field:400 }
                                                },                                              
                                                { label: t("alerte_sur_loeuvre"), attr: "alerte_oeuvre", type: "checkbox" , size: { label: 150, field: 100 }},

                                            ]} data={data} onChange={onChange} />
                                        </FormContainer>
                                        <FormContainer>
                                            <Form form={authentificationForm}
                                                data={data.authentification || {}}
                                                onChange={authentification => {
                                                    onChange({
                                                        ...data,
                                                        authentification
                                                    })
                                                }} 
                                            />
                                        </FormContainer>
                                    </TabsPanels>
                                )
                            },
                            ] : []),

                            {
                                label: t("prix"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("disponible_a_la_vente"), attr: "dispo_a_la_vente", type: "checkbox" , size: { label: 150, field: 100 }},
                                            ]} data={data} onChange={onChange} 
                                            />
                                        </FormContainer>
                                        <FormContainer>
                                            <Form form={prixForm} data={data.price || {}} onChange={price => {
                                                onChange({
                                                    ...data,
                                                    price
                                                })
                                            }} 
                                            />
                                        </FormContainer>


                                    </TabsPanels>
                                    )
                            },
                            {
                                label: t("assurance"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("inventaire"), attr: "inventaire", type: "checkbox", size: { label: 150, field: 100 } },
                                            ]} data={data} onChange={onChange}
                                            />
                                        </FormContainer>
                                        <FormContainer>
                                            <Form form={[
                                                [
                                                    { label: t("annee"), attr: "annee_min", type: "text", size: { label: 150, field: 100 } },
                                                    { label: "", attr: "annee_max", type: "text", size: { left: 20, label: 0, field: 100 } },
                                                ],
                                                [
                                                    { label: t("valeur"), attr: "valeur_min", type: "text", size: { label: 150, field: 100 } },
                                                    { label: "", attr: "valeur_max", type: "text", size: { left:20, label: 0, field: 100 } },
                                                ],
                                                { label: t("assureur"), attr: "assureur", type: "text", size: { label: 150, field: 400 } },
                                                { label: t("remarques"), attr: "remarque", type: "text", size: { label: 150, field: 400 } },
                                            ]} data={data.assurance || {}} onChange={assurance => {
                                                onChange({
                                                    ...data,
                                                    assurance
                                                })
                                            }} 
                                            />
                                        </FormContainer>

                                    </TabsPanels>
                                )
                            },

                        ]}

                    />

                </TabsContainer>

            </Grid>}

        </GridContainer>

    )
}