import React, { useState } from 'react';

import PageLayout from 'layouts/Helpers/PageLayout'

import {
    Button
} from '@material-ui/core';

import { getAllOeuvres, getOeuvreById, updateOeuvre, updateMultiple, getAllContacts, getContactById, updateContact } from 'Api';
import { containsGenre, addGenre, filtreOeuvresActuelles } from 'Util/Contacts';

import { displayOeuvreDerniereLocalisationFull, displayOeuvreDerniereLocalisation } from 'Util/OeuvreLocalisation';
import { displayOeuvreLastProprio } from 'Util/OeuvreProprietaire';
import { calcMultipleDatas } from 'Util/OeuvreMultiple';
import { getSurfaceDiameter, getSurfaceRect } from 'Util/Oeuvre';
import { isOeuvreMultiple } from 'Util/Oeuvre'
import { localisationsWithNumero } from 'Util/OeuvreMultiple'
import { calcLastLocalisation } from 'Util/OeuvreLocalisation';

import { LazyLog, ScrollFollow } from "@melloware/react-logviewer";

function ProgressBar({ progress, progressMax}) {
    // Styles pour la barre de progression
    const containerStyles = {
      height: '20px',
      width: '100%',
      backgroundColor: '#e0e0de',
      borderRadius: '50px',
      margin: '20px 0',
    };
  
    const fillerStyles = {
      height: '100%',
      width: `${100*progress/progressMax}%`,
      backgroundColor: 'blue',
      borderRadius: 'inherit',
      textAlign: 'right',
      transition: 'width 1s ease-in-out',
    };
  
    const labelStyles = {
      padding: '5px',
      color: 'white',
      fontWeight: 'bold',
    };
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${progress} / ${progressMax}`}</span>
        </div>
      </div>
    );
  }
  

const Layout = ({ initialData, reloadData = () => {}, onSaved = async () => {}, saveData = async () => {}, layout = 'edit' }) => {

    const [progress, setProgress] = useState(0);
    const [progressMax, setProgressMax] = useState(0); 
    const [logText, setLogText] = useState(""); 

    const fileName = "log.txt";
  
    const addLogInfo = (newInfo) => {
        setLogText((prevInfoString) => `${prevInfoString} ${newInfo}`);
    };

    const exportToFile = () => {
        // Créer un élément Blob qui contient le contenu du fichier
        const blob = new Blob([logText], { type: 'text/plain' });
    
        // Créer un URL pour le Blob
        const fileUrl = URL.createObjectURL(blob);
    
        // Créer un élément `<a>` temporaire pour déclencher le téléchargement
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link); // Ajout temporaire dans le document pour déclencher l'évènement click
        
        link.click(); // Simuler un clic sur le lien pour déclencher le téléchargement
        
        document.body.removeChild(link); // Nettoyer en retirant le lien du document
        URL.revokeObjectURL(fileUrl); // Libérer l'URL créée
    };

    const processOeuvres = async () => {

        const artworks = await getAllOeuvres()
        var oeuvres = artworks.oeuvres
        var nonMultiples = []

         oeuvres.sort(function (a, b) {
            return a.archive - b.archive;
        });

        for (let i = 0; i < oeuvres.length; i++) {
            if ( !isOeuvreMultiple(oeuvres[i]) ) {
                nonMultiples.push(oeuvres[i])
            }
        }
        setProgress(0);
        setProgressMax(nonMultiples.length);

        for (let i = 0; i < nonMultiples.length; i++) {
            await processOneOeuvre(nonMultiples[i]); // Attendre la fin de chaque appel API
            setProgress(i); // Mettre à jour la barre de progression
        }   
    }


    const processOneOeuvre = async (oeuvre) => {
        
        const id =  oeuvre.objectid
        const oeuvreFetch = await getOeuvreById({id})
        var data = oeuvreFetch.oeuvre
        
        addLogInfo("oeuvre : " + data.archive + "\n")

        data.dernier_proprietaire = displayOeuvreLastProprio(data)
        data.derniere_localisation_full = displayOeuvreDerniereLocalisationFull(data)
        data.derniere_localisation = displayOeuvreDerniereLocalisation(data)
        data.surface = data.diametre ? getSurfaceDiameter(data) : getSurfaceRect(data)
        await updateOeuvre({ id: data.objectid, data });
    }

   
    const testMultiple = async () => {

        const id = 217696
        const oeuvreFetch = await getOeuvreById({id})
        var data = oeuvreFetch.oeuvre
        
        await processOneMultiple(data); // Attendre la fin de chaque appel API
     
    }

    
    const processMultiples = async () => {

        const artworks = await getAllOeuvres()
        var oeuvres = artworks.oeuvres
        var multiples = []

         oeuvres.sort(function (a, b) {
            return a.archive - b.archive;
        });

        for (let i = 0; i < oeuvres.length; i++) {
            if ( isOeuvreMultiple(oeuvres[i]) ) {
                multiples.push(oeuvres[i])
            }
        }
        setProgress(0);
        setProgressMax(multiples.length);

        for (let i = 0; i < multiples.length; i++) {
            await processOneMultiple(multiples[i]); // Attendre la fin de chaque appel API
            setProgress(i); // Mettre à jour la barre de progression
        }   
    };

    const processOneMultiple = async (oeuvre) => {
        
        const id = oeuvre.objectid
        const oeuvreFetch = await getOeuvreById({id})
        var data = oeuvreFetch.oeuvre

        if ( isOeuvreMultiple(data) ) {

            calcMultipleDatas(data)

            const multiples = data.multiples

          for ( let i = 0; i < multiples.length; i++ ) {
                const multiple = multiples[i]
               // const emplacement = multiple.emplacement ? multiple.emplacement : ""
               // const lastEmplacement = multiple.dernier_emplacement ? multiple.dernier_emplacement : ""
                
                multiple.oeuvre_id = oeuvre.objectid
                await updateMultiple({ oeuvreid: multiple.oeuvre_id, data:multiple });

                addLogInfo("oeuvre : " + data.archive + " multiple : " + multiple.numero + "\n")

            }
        }
    }



    const checkEmplacements = async () => {

        const artworks = await getAllOeuvres()
        var oeuvres = artworks.oeuvres
        var multiples = []

         oeuvres.sort(function (a, b) {
            return a.archive - b.archive;
        });

        for (let i = 0; i < oeuvres.length; i++) {
            if ( isOeuvreMultiple(oeuvres[i]) ) {
                multiples.push(oeuvres[i])
            }
        }
        setProgress(0);
        setProgressMax(multiples.length);

        for (let i = 0; i < multiples.length; i++) {
            await checkOneEmplacement(multiples[i]); // Attendre la fin de chaque appel API
            setProgress(i); // Mettre à jour la barre de progression
        }   
    };

    const checkOneEmplacement = async (oeuvre) => {

        const id =  oeuvre.objectid
        const oeuvreFetch = await getOeuvreById({id})
        var data = oeuvreFetch.oeuvre

        if ( isOeuvreMultiple(data) ) {

            const multiples = data.multiples
            for ( let i = 0; i < multiples.length; i++ ) {
                const multiple = multiples[i]
                const emplacement = multiple.emplacement ? multiple.emplacement : ""
                const localisations = localisationsWithNumero(data.localisations, multiple.numero)
                var lastLocalisation = calcLastLocalisation(localisations)
                var lastEmplacement = ""
                
                if ( lastLocalisation && lastLocalisation.emplacement ) {
                    lastEmplacement = lastLocalisation.emplacement
                }
                if ( emplacement !== lastEmplacement ) {
                    addLogInfo("oeuvre : " + data.archive + " multiple : " + multiple.numero + " emplacement: " + emplacement + " lastEmplacement : " + lastEmplacement + "\n")
                }
            }
        }
    };

    const checkCollectionneurs = async () => {

        const alllContacts = await getAllContacts()
        var contacts = alllContacts.contacts

        setProgress(0);
        setProgressMax(contacts.length);

        for (let i = 0; i < contacts.length; i++) {

            const contactById = await getContactById({id:contacts[i].objectid})
            const contact = contactById.contact
            const oeuvres = contact.oeuvres_contact

            const isCollectionneur = containsGenre( contact, "collectionneur" )

            if ( isCollectionneur === true ) {
                console.log("deja collectionneur")
            }

            setProgress(i);
            addLogInfo("contact : " + contact.nom + " - " + contact.structure + "\n")
            
            for (let j = 0; j < oeuvres.length; j++) {
                const oeuvre = oeuvres[j]
                addLogInfo("oeuvre : " + oeuvre.archive + " - " + oeuvre.titre + "\n")
            }
        }   
    };

    const addCollectionneurs = async () => {

        const alllContacts = await getAllContacts()
        var contacts = alllContacts.contacts

        setProgress(0);
        setProgressMax(contacts.length);

        for (let i = 0; i < contacts.length; i++) {

            const contactId = contacts[i].objectid
            const contactById = await getContactById({id:contactId})
            const contact = contactById.contact
            const oeuvres = contact.oeuvres_contact
            const isCollectionneur = containsGenre( contact, "collectionneur" )

            setProgress(i);
            addLogInfo("contact : " + contact.nom + " - " + contact.structure + "\n")
            console.log("contact : ", contact)
            if ( isCollectionneur === true ) {
                console.log("deja collectionneur")
                addLogInfo("deja collectionneur\n")

            } else {

                const oeuvresActuelles = filtreOeuvresActuelles(oeuvres, contactId)

                if ( oeuvresActuelles && oeuvresActuelles.length > 0 ) {
                    addGenre(contact, "collectionneur")
                    await updateContact({ id: contactId, data: contact });
                    addLogInfo("add tag collectionneur\n")
                }
            }
           /* for (let j = 0; j < oeuvres.length; j++) {
                const oeuvre = oeuvres[j]
                addLogInfo("oeuvre : " + oeuvre.archive + " - " + oeuvre.titre + "\n")
            }*/
        }   
    };

    return (

        <PageLayout title="Maintenance">

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <Button style={{marginRight: '20px'}} key="test_multiple" onClick={() => testMultiple()} variant="contained">Test multiple</Button>
                    <Button style={{marginRight: '20px'}} key="oeuvre" onClick={() => processOeuvres()} variant="contained">Recalc Oeuvres</Button>
                    <Button style={{marginRight: '20px'}} key="new_multiple" onClick={() => processMultiples()} variant="contained">Recalc Multiples</Button>
                    <Button style={{marginRight: '20px'}} key="emplacement" onClick={() => checkEmplacements()} variant="contained">Check emplacement</Button>
                    <Button style={{marginRight: '20px'}} key="collectionneur" onClick={() => checkCollectionneurs()} variant="contained">Liste Collectionneurs</Button>
                    <Button style={{marginRight: '20px'}} key="add_collectionneur" onClick={() => addCollectionneurs()} variant="contained">Add Collectionneurs</Button>
                </div>
                <div>
                    <ProgressBar progress={progress} progressMax={progressMax}/>
                    <Button style={{marginRight: '20px'}} key="export" variant="contained" onClick={exportToFile}>Exporter le fichier texte</Button>

                </div>
                <div style={{height: '500px'}}>
                    <ScrollFollow
                        startFollowing={true}
                        render={({ follow, onScroll }) => (
                            <LazyLog text={logText} selectableLines="true" width="200" stream follow={follow} onScroll={onScroll} />
                        )}
                    />,                
                </div>
            </div>
        </PageLayout>

    )
}


export default ({ initialData, ...props } : Props) => {

    const key = initialData ? initialData.objectid : "create";
    return (
        <Layout {...props} initialData={initialData} key={key} />
    )
}