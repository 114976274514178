import Query from './Query';
import ApiCall from './ApiCall';
import { statusStringFromArray } from 'Util/OeuvreStatus'
import { cleanBigNumber } from "Util/Divers"


export default async ({ data: oeuvre }) => {
    const query = new Query();

    if (oeuvre.archive && oeuvre.archive_max) {
        query.matchNumberBetween({
            fieldName: 'archive',
            value_min: oeuvre.archive,
            value_max: oeuvre.archive_max
        })

    } else if ( oeuvre.archive ) {
        query.matchNumber({
            fieldName: 'archive',
            value: oeuvre.archive
        })
    }

   if (oeuvre.titre) {
        query.contains({
            fieldName: 'titre',
            value: oeuvre.titre
        })
    }

    if (oeuvre.autre_titre) {
        query.contains({
            fieldName: 'autre_titre',
            value: oeuvre.autre_titre
        })
    }

    if (oeuvre.mots_clefs) {
        query.contains({
            fieldName: 'mots_clefs',
            value: oeuvre.mots_clefs
        })
    }

    if (oeuvre.circa_creation) {
        query.matchNumber({
            fieldName: 'circa',
            value: oeuvre.circa_creation
        })
    }

    if (oeuvre.annee_creation_min && oeuvre.annee_creation_max) {

        query.matchNumberBetween({
            fieldName: 'annee_creation',
            value_min: oeuvre.annee_creation_min,
            value_max: oeuvre.annee_creation_max
        })
    } else if (oeuvre.annee_creation_min && !oeuvre.annee_creation_max) {

        query.matchNumber({
            fieldName: 'annee_creation',
            value: oeuvre.annee_creation_min,
        })
    }

    if (oeuvre.circa_realisation) {
        query.matchNumber({
            fieldName: 'circa_realisation',
            value: oeuvre.circa_realisation
        })
    }

    if (oeuvre.annee_realisation_min && oeuvre.annee_realisation_max) {

        query.matchNumberBetween({
            fieldName: 'annee_realisation',
            value_min: oeuvre.annee_realisation_min,
            value_max: oeuvre.annee_realisation_max
        })
    } else if (oeuvre.annee_realisation_min && !oeuvre.annee_realisation_max) {

        query.matchNumber({
            fieldName: 'annee_realisation',
            value: oeuvre.annee_realisation_min,
        })
    }

    if (oeuvre.type) {
        query.matchNumber({
            fieldName: 'type',
            value: oeuvre.type
        })
    }

    if (oeuvre.dernier_proprietaire) {
        query.contains({
            fieldName: 'dernier_proprietaire',
            value: oeuvre.dernier_proprietaire
        })
    }

    if (oeuvre.derniere_localisation && (oeuvre.match_derniere_localisation===1)) {
        query.exact_match_terms({
            fieldName: 'derniere_localisation',
            value: oeuvre.derniere_localisation
        })
    } else if (oeuvre.derniere_localisation) {
        query.containswildcard({
            fieldName: 'derniere_localisation_full',
            value: oeuvre.derniere_localisation
        })
    }

    if (oeuvre.statut_reserve) {
        query.matchNumber({
            fieldName: 'statut_reserve',
            value: oeuvre.statut_reserve
        })
    }

    if (oeuvre.largeur_min && oeuvre.largeur_max) {

        query.matchNumberBetween({
            fieldName: 'largeur',
            value_min: oeuvre.largeur_min,
            value_max: oeuvre.largeur_max
        })
    } else if (oeuvre.largeur_min && !oeuvre.largeur_max) {

        query.matchNumber({
            fieldName: 'largeur',
            value: oeuvre.largeur_min,
        })
    }

    if (oeuvre.hauteur_min && oeuvre.hauteur_max) {

        query.matchNumberBetween({
            fieldName: 'hauteur',
            value_min: oeuvre.hauteur_min,
            value_max: oeuvre.hauteur_max
        })
    } else if (oeuvre.hauteur_min && !oeuvre.hauteur_max) {

        query.matchNumber({
            fieldName: 'hauteur',
            value: oeuvre.hauteur_min,
        })
    }

    if (oeuvre.profondeur_min && oeuvre.profondeur_max) {

        query.matchNumberBetween({
            fieldName: 'profondeur',
            value_min: oeuvre.profondeur_min,
            value_max: oeuvre.profondeur_max
        })
    } else if (oeuvre.profondeur_min && !oeuvre.profondeur_max) {

        query.matchNumber({
            fieldName: 'profondeur',
            value: oeuvre.profondeur_min,
        })
    }

    if (oeuvre.diametre_min && oeuvre.diametre_max) {

        query.matchNumberBetween({
            fieldName: 'diametre',
            value_min: oeuvre.diametre_min,
            value_max: oeuvre.diametre_max
        })
    } else if (oeuvre.diametre_min && !oeuvre.diametre_max) {

        query.matchNumber({
            fieldName: 'diametre',
            value: oeuvre.diametre_min,
        })
    }

    if (oeuvre.dimensions_ht) {
        query.contains({
             fieldName: 'dimensions_ht',
            value: oeuvre.dimensions_ht
        })
    }

    if (oeuvre.mots_clefs) {
        query.contains({
             fieldName: 'mots_clefs',
            value: oeuvre.mots_clefs
        })
    }

    if (oeuvre.serie) {
        query.contains({
             fieldName: 'serie',
            value: oeuvre.serie
        })
    }

    if (oeuvre.editer_par) {
        query.contains({
            fieldName: 'editer_par',
            value: oeuvre.editer_par
        })
    }

    if (oeuvre.tirage) {
        query.contains({
            fieldName: 'tirage',
            value: oeuvre.tirage
        })
    }

    if (oeuvre.front_photo_hd) {
        query.matchNumber({
            fieldName: 'front_photo_hd',
            value: oeuvre.front_photo_hd
        })
    }

    if (oeuvre.pas_de_front_photo_hd) {
        query.matchNumber({
            fieldName: 'pas_de_front_photo_hd',
            value: oeuvre.pas_de_front_photo_hd
        })
    }

    if (oeuvre.video) {
        query.matchNumber({
            fieldName: 'video',
            value: oeuvre.video
        })
    }

    //
    //  specifications techniques
    //
    if (oeuvre.fiche_technique) {
        query.matchNumber({
            fieldName: 'fiche_technique',
            value: oeuvre.fiche_technique
        })
    }

    if (oeuvre.date_fiche_technique_min && oeuvre.date_fiche_technique_max) {

        query.matchDateBetween({
            fieldName: 'date_fiche_technique',
             date_min: oeuvre.date_fiche_technique_min,
            date_max: oeuvre.date_fiche_technique_max
         })
    } else if (oeuvre.date_fiche_technique_min && !oeuvre.date_fiche_technique_max) {
         query.matchDate({
            fieldName: 'date_fiche_technique',
            value: oeuvre.date_fiche_technique_min,
          })
    }

    if (oeuvre.nb_tacos && oeuvre.nb_tacos_max) {

        query.matchNumberBetween({
            fieldName: 'nb_tacos',
            value_min: oeuvre.nb_tacos,
            value_max: oeuvre.nb_tacos_max
        })
    } else if (oeuvre.nb_tacos && !oeuvre.nb_tacos_max) {

        query.matchNumber({
            fieldName: 'nb_tacos',
            value: oeuvre.nb_tacos,
        })
    }

    if (oeuvre.nb_varillas && oeuvre.nb_varillas_max) {

        query.matchNumberBetween({
            fieldName: 'nb_varillas',
            value_min: oeuvre.nb_varillas,
            value_max: oeuvre.nb_varillas_max
        })
    } else if (oeuvre.nb_varillas && !oeuvre.nb_varillas_max) {

        query.matchNumber({
            fieldName: 'nb_varillas',
            value: oeuvre.nb_varillas,
        })
    }


    if (oeuvre.nb_tes && oeuvre.nb_tes_max) {

        query.matchNumberBetween({
            fieldName: 'nb_tes',
            value_min: oeuvre.nb_tes,
            value_max: oeuvre.nb_tes_max
        })
    } else if (oeuvre.nb_tes && !oeuvre.nb_tes_max) {

        query.matchNumber({
            fieldName: 'nb_tes',
            value: oeuvre.nb_tes,
        })
    }

    if (oeuvre.couleurs_array) {
        oeuvre.couleurs_array.forEach( couleur => {
            query.contains({
                fieldName: 'couleurs_string',
                value: couleur["value"]
            })
        })
    }
    if (oeuvre.elements_array) {
        oeuvre.elements_array.forEach( element => {
            query.contains({
                fieldName: 'elements_string',
                value: element["value"]
            })
        })
    }
    if (oeuvre.familles_array) {
        oeuvre.familles_array.forEach( famille => {
            query.contains({
                fieldName: 'familles_string',
                value: famille["value"]
            })
        })
    }
    if (oeuvre.materiaux_array) {
        oeuvre.materiaux_array.forEach( materiau => {
            query.contains({
                fieldName: 'materiaux_string',
                value: materiau["value"]
            })
        })
    }


    if (oeuvre.details_techniques) {
        query.contains({
            fieldName: 'details_techniques',
            value: oeuvre.details_techniques
        })
    }

    if (oeuvre.contraintes_installation) {
        query.contains({
            fieldName: 'contraintes_installation',
            value: oeuvre.contraintes_installation
        })
    }

    if (oeuvre.materiel_necessaire) {
        query.contains({
            fieldName: 'materiel_necessaire',
            value: oeuvre.materiel_necessaire
        })
    }

    if (oeuvre.autres_infos_techniques) {
        query.contains({
            fieldName: 'autres_infos_techniques',
            value: oeuvre.autres_infos_techniques
        })
    }



    //
    //  Multiple
    //
    if (oeuvre.multiple) {

        if (oeuvre.multiple.numero) {
            query.contains({
                fieldName: 'multiples.numero',
                value: oeuvre.multiple.numero
            })
        }

        if (oeuvre.multiple.derniere_localisation) {
            query.contains({
                fieldName: 'multiples.derniere_localisation',
                value: oeuvre.multiple.derniere_localisation
            })
        }


        if (oeuvre.multiple.dernier_proprietaire) {
            query.contains({
                fieldName: 'multiples.dernier_proprietaire',
                value: oeuvre.multiple.dernier_proprietaire
            })
        }

        if (oeuvre.multiple.emplacement) {
            query.contains({
                fieldName: 'multiples.emplacement',
                value: oeuvre.multiple.emplacement
            })
        }

        if (oeuvre.multiple.etat) {
            query.matchTextExact({
                fieldName: 'multiples.etat',
                value: oeuvre.multiple.etat
            })
        }

        if (oeuvre.multiple.etat_de_conservation) {
            query.matchText({
                fieldName: 'multiples.etat_de_conservation',
                value: oeuvre.multiple.etat_de_conservation
            })
        }

        if (oeuvre.multiple.etat_de_conservation) {
            query.matchText({
                fieldName: 'multiples.etat_de_conservation',
                value: oeuvre.multiple.etat_de_conservation
            })
        }
        if (oeuvre.multiple.nonsigne) {
            query.matchNumber({
                fieldName: 'multiples.nonsigne',
                value: oeuvre.multiple.nonsigne
            })
        }

        if (oeuvre.multiple.nonnumerote) {
            query.matchNumber({
                fieldName: 'multiples.nonnumerote',
                value: oeuvre.multiple.nonnumerote
            })
        }

        if (oeuvre.multiple.multiple_remarques) {
            query.contains({
                fieldName: 'multiples.remarques',
                value: oeuvre.multiple.multiple_remarques
            })
        }
        if (oeuvre.multiple.multiple_conservation) {
            query.contains({
                fieldName: 'multiples.conservation',
                value: oeuvre.multiple.multiple_conservation
            })
        }


    }

    //
    //   Localisation
    //
    if ( oeuvre.loc ) {
        if ( oeuvre.loc.date_localisation ) {

            if ( oeuvre.loc.date_localisation_max ) {
                query.matchDateBetween({
                    fieldName: 'localisations.date_localisation',
                    date_min: oeuvre.loc.date_localisation,
                    date_max: oeuvre.loc.date_localisation_max
                })
            } else {
                query.matchDate({
                    fieldName: 'localisations.date_localisation',
                    value: oeuvre.loc.date_localisation,
                })
            }
        }


        if (oeuvre.loc.multiple) {
            query.contains({
                fieldName: 'localisations.multiple',
                value: oeuvre.loc.multiple
            })
        }

        if (oeuvre.loc.emplacement) {
            query.contains({
                fieldName: 'localisations.emplacement',
                value: oeuvre.loc.emplacement
            })

        }

        if (oeuvre.loc.localisation) {
            query.contains({
                fieldName: 'localisations.localisation',
                value: oeuvre.loc.localisation
            })

        }

        if (oeuvre.loc.ville) {
            query.contains({
                fieldName: 'localisations.ville',
                value: oeuvre.loc.ville
            })
        }

        if (oeuvre.loc.pays) {
            query.contains({
                fieldName: 'localisations.pays',
                value: oeuvre.loc.pays
            })
        }

        if (oeuvre.loc.remarque) {
            query.contains({
                fieldName: 'localisations.remarque',
                value: oeuvre.loc.remarque
            })
        }
    }
    //
    //  Conservation / Restauration
    //

    if (oeuvre.a_restaurer) {
        query.matchNumber({
            fieldName: 'a_restaurer',
            value: oeuvre.a_restaurer
        })
    }
    if (oeuvre.detruit) {
        query.matchNumber({
            fieldName: 'detruit',
            value: oeuvre.detruit
        })
    }
    if (oeuvre.nonrestaurable) {
        query.matchNumber({
            fieldName: 'nonrestaurable',
            value: oeuvre.nonrestaurable
        })
    }

    if (oeuvre.date_ouverture_min && oeuvre.date_ouverture_max) {

        query.matchDateBetween({
            fieldName: 'date_ouverture',
             date_min: oeuvre.date_ouverture_min,
            date_max: oeuvre.date_ouverture_max
         })
    } else if (oeuvre.date_ouverture_min && !oeuvre.date_ouverture_max) {
         query.matchDate({
            fieldName: 'date_ouverture',
            value: oeuvre.date_ouverture_min,
          })
    }

    if (oeuvre.date_constat_min && oeuvre.date_constat_max) {

        query.matchDateBetween({
            fieldName: 'date_constat',
             date_min: oeuvre.date_constat_min,
            date_max: oeuvre.date_constat_max
         })
    } else if (oeuvre.date_constat_min && !oeuvre.date_constat_max) {
         query.matchDate({
            fieldName: 'date_constat',
            value: oeuvre.date_constat_min,
          })
    }

    if (oeuvre.realise_par) {
        query.contains({
            fieldName: 'realise_par',
            value: oeuvre.realise_par
        })
    }

    if (oeuvre.restauration) {
        query.contains({
            fieldName: 'restauration',
            value: oeuvre.restauration
        })
    }

    //
    //  Transport / Caisse
    //
    if (oeuvre.passeport) {
        query.matchNumber({
            fieldName: 'passeport',
            value: oeuvre.passeport
        })
    }
    if (oeuvre.historique) {
        query.contains({
            fieldName: 'historique',
            value: oeuvre.historique
        })
    }

    if (oeuvre.caisse ) {

        if (oeuvre.caisse.nimp15) {
            query.matchNumber({
                fieldName: 'caisse_transports.nimp15',
                value: oeuvre.caisse.nimp15
            })
        }


        if (oeuvre.caisse.multiple) {
            query.contains({
                fieldName: 'caisse_transports.multiple',
                value: oeuvre.caisse.multiple
            })
        }

        if (oeuvre.caisse.largeur && oeuvre.caisse.largeur_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.largeur',
                value_min: oeuvre.caisse.largeur,
                value_max: oeuvre.caisse.largeur_max
            })
        } else if (oeuvre.caisse.largeur) {

            query.matchNumber({
                fieldName: 'caisse_transports.largeur',
                value: oeuvre.caisse.largeur,
            })
        }

        if (oeuvre.caisse.hauteur && oeuvre.caisse.hauteur_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.hauteur',
                value_min: oeuvre.caisse.hauteur,
                value_max: oeuvre.caisse.hauteur_max
            })
        } else if (oeuvre.caisse.hauteur) {

            query.matchNumber({
                fieldName: 'caisse_transports.hauteur',
                value: oeuvre.caisse.hauteur,
            })
        }
                if (oeuvre.caisse.profondeur && oeuvre.caisse.profondeur_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.profondeur',
                value_min: oeuvre.caisse.profondeur,
                value_max: oeuvre.caisse.profondeur_max
            })
        } else if (oeuvre.caisse.profondeur) {

            query.matchNumber({
                fieldName: 'caisse_transports.profondeur',
                value: oeuvre.caisse.profondeur,
            })
        }
        if (oeuvre.caisse.poids && oeuvre.caisse.poids_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.poids',
                value_min: oeuvre.caisse.poids,
                value_max: oeuvre.caisse.poids_max
            })
        } else if (oeuvre.caisse.poids) {

            query.matchNumber({
                fieldName: 'caisse_transports.poids',
                value: oeuvre.caisse.poids,
            })
        }

        if (oeuvre.caisse.poids_net && oeuvre.caisse.poids_net_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.poids_net',
                value_min: oeuvre.caisse.poids_net,
                value_max: oeuvre.caisse.poids_net_max
            })
        } else if (oeuvre.caisse.poids_net) {

            query.matchNumber({
                fieldName: 'caisse_transports.poids_net',
                value: oeuvre.caisse.poids_net,
            })
        } else if (oeuvre.caisse.poids_net_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.poids_net',
                value_min: 0,
                value_max: oeuvre.caisse.poids_net_max
            })
       }
        if (oeuvre.caisse.poids_brut && oeuvre.caisse.poids_brut_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.poids_brut',
                value_min: oeuvre.caisse.poids_brut,
                value_max: oeuvre.caisse.poids_brut_max
            })
        } else if (oeuvre.caisse.poids) {

            query.matchNumber({
                fieldName: 'caisse_transports.poids',
                value: oeuvre.caisse.poids,
            })
        } else if (oeuvre.caisse.poids_brut_max) {

            query.matchNumberBetween({
                fieldName: 'caisse_transports.poids_brut',
                value_min: 0,
                value_max: oeuvre.caisse.poids_brut_max
            })
        }
        if (oeuvre.caisse.etat) {
            query.contains({
                fieldName: 'caisse_transports.etat',
                value: oeuvre.caisse.etat
            })
        }

         if (oeuvre.caisse.cadre_de_voyage) {
            query.matchNumber({
                fieldName: 'caisse_transports.cadre_de_voyage',
                value: oeuvre.caisse.cadre_de_voyage
            })
        }

        if (oeuvre.caisse.etat) {
            query.matchNumber({
                fieldName: 'caisse_transports.etat',
                value: oeuvre.caisse.etat
            })
        }
        if (oeuvre.caisse.ouverture_verticale) {
            query.matchNumber({
                fieldName: 'caisse_transports.ouverture_verticale',
                value: oeuvre.caisse.ouverture_verticale
            })
        }
        if (oeuvre.caisse.peinte) {
            query.matchNumber({
                fieldName: 'caisse_transports.peinte',
                value: oeuvre.caisse.peinte
            })
        }
        if (oeuvre.caisse.a_refaire) {
            query.matchNumber({
                fieldName: 'caisse_transports.a_refaire',
                value: oeuvre.caisse.a_refaire
            })
        }
        if (oeuvre.caisse.tyvek_ok) {
            query.matchNumber({
                fieldName: 'caisse_transports.tyvek_ok',
                value: oeuvre.caisse.tyvek_ok
            })
        }
        if (oeuvre.caisse.cales_ok) {
            query.matchNumber({
                fieldName: 'caisse_transports.cales_ok',
                value: oeuvre.caisse.cales_ok
            })
        }
    }

    //
    //  Observations
    //

    if (oeuvre.etat_fiche) {

        const etatString = statusStringFromArray(oeuvre.etat_fiche)
        const etatArray = etatString.split(",")

        query.contains({
            fieldName: 'etat_fiche',
            value: etatArray[0]
        })
    }

    if (oeuvre.source) {
        query.contains({
            fieldName: 'source',
            value: oeuvre.source
        })
    }

    if (oeuvre.doublon) {
        query.matchNumber({
            fieldName: 'doublon',
            value: oeuvre.doublon
        })
    }

    if (oeuvre.alerte_oeuvre) {
        query.matchNumber({
              fieldName: 'alerte_oeuvre',
              value: oeuvre.alerte_oeuvre
           })
    }

     if (oeuvre.observations_search) {
        query.contains({
            fieldName: 'observations',
            value: oeuvre.observations_search
        })
    }

    //
    //  Fabrication
    //
    if (oeuvre.fabrique_par) {
        query.contains({
            fieldName: 'fabrique_par',
            value: oeuvre.fabrique_par
        })
    }

    if (oeuvre.code) {
        query.contains({
            fieldName: 'code',
            value: oeuvre.code
        })
    }

    if (oeuvre.plan) {
        query.matchNumber({
            fieldName: 'plan',
            value: oeuvre.plan
        })
    }

    if (oeuvre.fiche_technique_verifie_comite) {
        query.matchNumber({
            fieldName: 'fiche_technique_verifie_comite',
            value: oeuvre.fiche_technique_verifie_comite
        })
    }

    if (oeuvre.fabrication_observations) {
        query.contains({
            fieldName: 'fabrication_observations',
            value: oeuvre.fabrication_observations
        })
    }
    //
    //  Code Avila
    //
    if (oeuvre.code_direction) {
        query.contains({
            fieldName: 'code_direction',
            value: oeuvre.code_direction
        })
    }
    if (oeuvre.code_assistant_direction) {
        query.contains({
            fieldName: 'code_assistant_direction',
            value: oeuvre.code_assistant_direction
        })
    }
    if (oeuvre.code_regisseur) {
        query.contains({
            fieldName: 'code_regisseur',
            value: oeuvre.code_regisseur
        })
    }
    if (oeuvre.code_assistant_regie) {
        query.contains({
            fieldName: 'code_assistant_regie',
            value: oeuvre.code_assistant_regie
        })
    }
    //
    //  Historique / Provenance
    //
    if ( oeuvre.provenance ) {

        if (oeuvre.provenance.date_historique_min && oeuvre.provenance.date_historique_max) {

            query.matchDateBetween({
                fieldName: 'historiques.date_historique',
                date_min: oeuvre.provenance.date_historique_min,
                date_max: oeuvre.provenance.date_historique_max
            })
        } else if (oeuvre.provenance.date_historique_min ) {

            query.matchDate({
                fieldName: 'historiques.date_historique',
                value: oeuvre.provenance.date_historique_min,
            })
        }

        if (oeuvre.provenance.type) {
            query.contains({
                fieldName: 'historiques.type',
                value: oeuvre.provenance.type
            })
        }

        if (oeuvre.provenance.ancien_proprio) {
            query.contains({
                fieldName: 'historiques.ancien_proprio.structure_nom_prenom',
                value: oeuvre.provenance.ancien_proprio
            })
        }

        if (oeuvre.provenance.nouveau_proprio) {
            query.contains({
                fieldName: 'historiques.nouveau_proprio.structure_nom_prenom',
                value: oeuvre.provenance.nouveau_proprio
            })
        }
    }

    if (oeuvre.remarques_provenance) {
        query.contains({
            fieldName: 'remarques_provenance',
            value: oeuvre.remarques_provenance
        })
    }

    //
    //  Authentification
    //
    if (oeuvre.remarques_authentification) {
        query.contains({
            fieldName: 'remarques_authentification',
            value: oeuvre.remarques_authentification
        })
    }


    if ( oeuvre.authentification ) {

        if (oeuvre.authentification.date_auth_min && oeuvre.authentification.date_auth_max) {

            query.matchNumberBetween({
                fieldName: 'authentifications.date_authentification',
                value_min: oeuvre.authentification.date_auth_min,
                value_max: oeuvre.authentification.date_auth_max
            })
        } else if (oeuvre.authentification.date_auth_min && !oeuvre.authentification.date_auth_max) {

            query.matchNumber({
                fieldName: 'authentifications.date_authentification',
                value: oeuvre.authentification.date_auth_min,
            })
        }


        if (oeuvre.authentification.source_auth) {
            query.contains({
                fieldName: 'authentifications.source',
                value: oeuvre.authentification.source_auth
            })
        }

        if (oeuvre.authentification.status_auth) {
            query.contains({
                fieldName: 'authentifications.status',
                value: oeuvre.authentification.status_auth
            })
        }
        if (oeuvre.authentification.remarques_auth) {
            query.contains({
                fieldName: 'authentifications.remarques',
                value: oeuvre.authentification.remarques_auth
            })
        }
        if (oeuvre.authentification.caisse) {
            query.contains({
                fieldName: 'authentifications.caisse',
                value: oeuvre.authentification.caisse
            })
        }

        if (oeuvre.authentification.contrat_envoye && oeuvre.authentification.contrat_envoye_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.contrat_envoye',
                date_min: oeuvre.authentification.contrat_envoye,
                date_max: oeuvre.authentification.contrat_envoye_max
            })
        } else if (oeuvre.authentification.contrat_envoye) {

            query.matchDate({
                fieldName: 'authentifications.contrat_envoye',
                value: oeuvre.authentification.contrat_envoye,
            })
        }

        if (oeuvre.authentification.contrat_recu && oeuvre.authentification.contrat_recu_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.contrat_recu',
                date_min: oeuvre.authentification.contrat_recu,
                date_max: oeuvre.authentification.contrat_recu_max
            })
        } else if (oeuvre.authentification.contrat_recu && !oeuvre.authentification.contrat_recu_max) {

            query.matchDate({
                fieldName: 'authentifications.contrat_recu',
                value: oeuvre.authentification.contrat_recu,
            })
        }

        if (oeuvre.authentification.paiement && oeuvre.authentification.paiement_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.paiement',
                date_min: oeuvre.authentification.paiement,
                date_max: oeuvre.authentification.paiement_max
            })
        } else if (oeuvre.authentification.paiement && !oeuvre.authentification.paiement_max) {

            query.matchDate({
                fieldName: 'authentifications.paiement',
                value: oeuvre.authentification.paiement,
            })
        }

        if (oeuvre.authentification.document_didentite && oeuvre.authentification.document_didentite_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.document_didentite',
                date_min: oeuvre.authentification.document_didentite,
                date_max: oeuvre.authentification.document_didentite_max
            })
        } else if (oeuvre.authentification.document_didentite && !oeuvre.authentification.document_didentite_max) {

            query.matchDate({
                fieldName: 'authentifications.document_didentite',
                value: oeuvre.authentification.document_didentite,
            })
        }

        if (oeuvre.authentification.date_photo_hd && oeuvre.authentification.date_photo_hdmax) {
            query.matchDateBetween({
                fieldName: 'authentifications.date_photo_hd',
                date_min: oeuvre.authentification.date_photo_hd,
                date_max: oeuvre.authentification.date_photo_hd_max
            })
        } else if (oeuvre.authentification.date_photo_hd && !oeuvre.authentification.date_photo_hd_max) {

            query.matchDate({
                fieldName: 'authentifications.date_photo_hd',
                value: oeuvre.authentification.date_photo_hd,
            })
        }

        if (oeuvre.authentification.arrivee_oeuvre && oeuvre.authentification.arrivee_oeuvre_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.arrivee_oeuvre',
                date_min: oeuvre.authentification.arrivee_oeuvre,
                date_max: oeuvre.authentification.arrivee_oeuvre_max
            })
        } else if (oeuvre.authentification.arrivee_oeuvre && !oeuvre.authentification.arrivee_oeuvre_max) {

            query.matchDate({
                fieldName: 'authentifications.arrivee_oeuvre',
                value: oeuvre.authentification.arrivee_oeuvre,
            })
        }

        if (oeuvre.authentification.retour_oeuvre && oeuvre.authentification.retour_oeuvre_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.retour_oeuvre',
                date_min: oeuvre.authentification.retour_oeuvre,
                date_max: oeuvre.authentification.retour_oeuvre_max
            })
        } else if (oeuvre.authentification.retour_oeuvre && !oeuvre.authentification.retour_oeuvre_max) {

            query.matchDate({
                fieldName: 'authentifications.retour_oeuvre',
                value: oeuvre.authentification.retour_oeuvre,
            })
        }

        if (oeuvre.authentification.certificat && oeuvre.authentification.certificat_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.certificat',
                date_min: oeuvre.authentification.certificat,
                date_max: oeuvre.authentification.certificat_max
            })
        } else if (oeuvre.authentification.certificat && !oeuvre.authentification.certificat_max) {

            query.matchDate({
                fieldName: 'authentifications.certificat',
                value: oeuvre.authentification.certificat,
            })
        }

        if (oeuvre.authentification.signature && oeuvre.authentification.signature_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.signature',
                date_min: oeuvre.authentification.signature,
                date_max: oeuvre.authentification.signature_max
            })
        } else if (oeuvre.authentification.signature && !oeuvre.authentification.signature_max) {

            query.matchDate({
                fieldName: 'authentifications.signature',
                value: oeuvre.authentification.signature,
            })
        }

        if (oeuvre.authentification.envoi_certificat && oeuvre.authentification.envoi_certificat_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.envoi_certificat',
                date_min: oeuvre.authentification.envoi_certificat,
                date_max: oeuvre.authentification.envoi_certificat_max
            })
        } else if (oeuvre.authentification.envoi_certificat && !oeuvre.authentification.envoi_certificat_max) {

            query.matchDate({
                fieldName: 'authentifications.envoi_certificat',
                value: oeuvre.authentification.envoi_certificat,
            })
        }

        if (oeuvre.authentification.reception_certificat && oeuvre.authentification.reception_certificat_max) {
            query.matchDateBetween({
                fieldName: 'authentifications.reception_certificat',
                date_min: oeuvre.authentification.reception_certificat,
                date_max: oeuvre.authentification.reception_certificat_max
            })
        } else if (oeuvre.authentification.reception_certificat && !oeuvre.authentification.reception_certificat_max) {

            query.matchDate({
                fieldName: 'authentifications.reception_certificat',
                value: oeuvre.authentification.reception_certificat,
            })
        }
    }

    //
    //  Prix
    //
    if (oeuvre.dispo_a_la_vente) {
        query.matchNumber({
            fieldName: 'dispo_a_la_vente',
            value: oeuvre.dispo_a_la_vente
        })
    }

    if ( oeuvre.price ) {

        const surface_min = Math.round(parseFloat(oeuvre.price.surface_min)*100*100)
        const surface_max = Math.round(parseFloat(oeuvre.price.surface_max)*100*100)

        if (oeuvre.price.surface_min && oeuvre.price.surface_max) {

            query.matchNumberBetween({
                fieldName: 'surface',
                value_min: surface_min,
                value_max: surface_max
            })
        } else if (oeuvre.price.surface_min && !oeuvre.price.surface_max) {

            query.matchNumber({
                fieldName: 'surface',
                value: surface_min
            })
        }


        const estimation_min = cleanBigNumber(oeuvre.price.estimation_min)
        const estimation_max = cleanBigNumber(oeuvre.price.estimation_min)
        const prix_de_vente_min = cleanBigNumber(oeuvre.price.prix_de_vente_min)
        const prix_de_vente_max = cleanBigNumber(oeuvre.price.prix_de_vente_max)
        const prix_au_marteau_min = cleanBigNumber(oeuvre.price.prix_au_marteau_min)
        const prix_au_marteau_max = cleanBigNumber(oeuvre.price.prix_au_marteau_max)

        if (oeuvre.price.date_enchere_min && oeuvre.price.date_enchere_max) {

            query.matchDateBetween({
                fieldName: 'encheres.date_enchere',
                date_min: oeuvre.price.date_enchere_min,
                date_max: oeuvre.price.date_enchere_max
            })
        } else if (oeuvre.price.date_enchere_min && !oeuvre.price.date_enchere_max) {

            query.matchDate({
                fieldName: 'encheres.date_enchere',
                value: oeuvre.price.date_enchere_min,
            })
        }

        if (prix_de_vente_min && prix_de_vente_max) {

            query.matchNumberBetween({
                fieldName: 'encheres.prix_de_vente',
                value_min: prix_de_vente_min,
                value_max: prix_de_vente_max
            })
        } else if (prix_de_vente_min && !prix_de_vente_max) {

            query.matchNumber({
                fieldName: 'encheres.prix_de_vente',
                value: oeuvre.price.prix_de_vente_min,
            })
        }

        if (prix_au_marteau_min && prix_au_marteau_max) {

            query.matchNumberBetween({
                fieldName: 'encheres.prix_marteau',
                value_min: prix_au_marteau_min,
                value_max: prix_au_marteau_max
            })
        } else if (prix_au_marteau_min && !prix_au_marteau_max) {

            query.matchNumber({
                fieldName: 'encheres.prix_marteau',
                value: prix_au_marteau_min,
            })
        }

        if (estimation_min && estimation_max) {

            query.matchNumberBetween({
                fieldName: 'encheres.estimation_min',
                value_min: estimation_min,
                value_max: estimation_max
            })
        } else if (estimation_min && !estimation_max) {

            query.matchNumber({
                fieldName: 'encheres.estimation_min',
                value: estimation_max,
            })
        }


        if (oeuvre.price.source) {
            query.contains({
                fieldName: 'encheres.source',
                value: oeuvre.price.source
            })
        }

        if (oeuvre.price.type_prix) {
            query.contains({
                fieldName: 'encheres.type',
                value: oeuvre.price.type_prix
            })
        }

        if (oeuvre.price.multiple) {
            query.contains({
                fieldName: 'encheres.multiple',
                value: oeuvre.price.multiple
            })
        }

        if (oeuvre.price.lot) {
            query.matchNumber({
                fieldName: 'encheres.lot',
                value: oeuvre.price.lot
            })
        }

        if (oeuvre.price.devise_prix_de_vente) {
            query.contains({
                fieldName: 'encheres.devise_prix_de_vente',
                value: oeuvre.price.devise_prix_de_vente
            })
        }

        if (oeuvre.price.ville) {
            query.contains({
                fieldName: 'encheres.ville',
                value: oeuvre.price.ville
            })
        }

        if (oeuvre.price.pays) {
            query.contains({
                fieldName: 'encheres.pays',
                value: oeuvre.price.pays
            })
        }

        if (oeuvre.price.remarques) {
            query.contains({
                fieldName: 'encheres.remarques',
                value: oeuvre.price.remarques
            })
        }
    }

    //
    //  Assurance
    //
    if (oeuvre.inventaire) {
        query.matchNumber({
            fieldName: 'inventaire',
            value: oeuvre.inventaire
        })
    }

    if ( oeuvre.assurance ) {

        if (oeuvre.assurance.annee_min && oeuvre.assurance.annee_max) {

            query.matchNumberBetween({
                fieldName: 'assurances.annee',
                value_min: oeuvre.assurance.annee_min,
                value_max: oeuvre.assurance.annee_max
            })
        } else if (oeuvre.assurance.annee_min && !oeuvre.assurance.annee_max) {

            query.matchNumber({
                fieldName: 'assurances.annee',
                value: oeuvre.assurance.annee_min,
            })
        }


        if (oeuvre.assurance.valeur_min && oeuvre.assurance.valeur_max) {

            query.matchNumberBetween({
                fieldName: 'assurances.valeur',
                value_min: oeuvre.assurance.valeur_min,
                value_max: oeuvre.assurance.valeur_max
            })
        } else if (oeuvre.assurance.valeur_min && !oeuvre.assurance.valeur_max) {

            query.matchNumber({
                fieldName: 'assurances.valeur',
                value: oeuvre.assurance.valeur_min,
            })
        }

        if (oeuvre.assurance.assureur) {
            query.contains({
                fieldName: 'assurances.assureur',
                value: oeuvre.assurance.assureur
            })
        }

        if (oeuvre.assurance.remarque) {
            query.contains({
                fieldName: 'assurances.remarque',
                value: oeuvre.assurance.remarque
            })
        }
    }

    console.log("query oeuvre", query);

    return await ApiCall({ type: 'oeuvre', query })
}